/* eslint-disable compat/compat */
import { subject } from "@casl/ability";
import { FileWithPath } from "@mantine/dropzone";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import {
  serverTimestamp,
  Timestamp,
  FirestoreDataConverter,
  collection,
  getCountFromServer,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  getDoc
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { uploadAnexosFilesToStorage } from "./storage";
import { getEmpresaDocumentReference } from "../empresas/firestore";

const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nome", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TProjetoFormFields = Omit<
  TProjeto,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TProjeto, "id" | "refPath">>;

export type TProjetoDatabaseFields = Pick<TProjeto, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TProjetoForm = TProjetoFormFields & TProjetoDatabaseFields;

type TProjetoDocument = Omit<TProjeto, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const projetoConverter: FirestoreDataConverter<TProjeto> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, deletedAt, ...document } = snap.data() as TProjetoDocument;

    const data: TProjeto = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      deletedAt: deletedAt ? deletedAt.toDate() : null,
      refPath: snap.ref.path
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return subject("TProjeto", data);
  }
};

/**
 * Get projetos collection reference from empresaId
 * @param empresaId - Empresa id to get the projetos collection reference
 * @param licenciadaId - Licenciada Id
 * @returns - Projetos collection reference
 */
function getProjetosCollectionReference(empresaId: TEmpresa["id"], licenciadaId?: TLicenciada["id"] | undefined) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId, licenciadaId);
  return collection(empresaDocumentReference, "projetos");
}

/**
 * Get projeto document reference from empresaId
 * @param empresaId - Empresa id to get the projeto document reference
 * @param projetoId - Projeto id to get the projeto document reference *
 * @param licenciadaId - Licenciada Id
 * @returns - Projeto document reference
 */
export function getProjetoDocumentReference(
  empresaId: TEmpresa["id"],
  projetoId: TProjeto["id"],
  licenciadaId?: TLicenciada["id"]
) {
  const projetosCollectionReference = getProjetosCollectionReference(empresaId, licenciadaId);
  return doc(projetosCollectionReference, projetoId);
}

/**
 * Get projeto document reference from empresaId
 * @param empresaId - Empresa id to get the projeto document reference
 * @param projetoId - Projeto id to get the projeto document reference
 * @returns - Projeto document reference
 */
export function getProjetoDocumentReferenceWithConverter(empresaId: TEmpresa["id"], projetoId: TProjeto["id"]) {
  const projetosCollectionReference = getProjetosCollectionReference(empresaId);
  return doc(projetosCollectionReference, projetoId).withConverter(projetoConverter);
}

const BASE_FILTER: ColumnFiltersState = [
  {
    id: "deletedAt",
    value: ["==", null]
  }
];

/**
 * Get projetos documents from empresaId
 * @param empresaId - Empresa id to get the projetos documents
 * @param options - Options to get the projetos documents
 * @param options.sorting - Sorting options
 * @param options.filters - Filters options
 * @param options.pagination - Pagination options
 * @param options.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Projetos documents
 */
export function getProjetos(
  empresaId: TEmpresa["id"],
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors
  }: TPaginatedQueryOptions<TProjeto> = {}
): Promise<TPaginatedQueryResponse<TProjeto>> {
  const projetosCollectionReference = getProjetosCollectionReference(empresaId).withConverter(projetoConverter);

  const allFilters = [...BASE_FILTER, ...filters];
  const qAll = generateQuery(projetosCollectionReference, { filters: allFilters });
  const qPaginated = generateQuery(projetosCollectionReference, {
    sorting,
    filters: allFilters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

/**
 * Add a new projeto to the given empresa
 * @param empresaId - Empresa id to add the projeto
 * @param projeto - Projeto data
 * @returns - Projeto document reference
 */
export async function addProjeto(empresaId: TEmpresa["id"], projeto: Omit<TProjetoForm, "id" | "refPath">) {
  const projetosCollectionReference = getProjetosCollectionReference(empresaId);

  const { images } = projeto;
  const collectionReference = getProjetosCollectionReference(empresaId);
  const projetoDocument = doc(collectionReference);
  const anexosPromises = images.map<Promise<TFileStored>>((file: FileWithPath) =>
    uploadAnexosFilesToStorage(empresaId, projetoDocument.id, file).then(async (uploadTaskSnapshot) => {
      const url = await getDownloadURL(uploadTaskSnapshot.ref);
      return {
        path: url,
        name: uploadTaskSnapshot.metadata.name,
        size: uploadTaskSnapshot.metadata.size,
        type: uploadTaskSnapshot.metadata.contentType || ""
      };
    })
  );
  // eslint-disable-next-line compat/compat
  const anexos = await Promise.all(anexosPromises);

  return addDoc(projetosCollectionReference, {
    ...projeto,
    images: anexos
  });
}

/**
 * Get started projetos query
 * @param empresaId - Empresa id
 * @returns - projetos document reference
 */
function queryProjetos(empresaId: TEmpresa["id"]) {
  const projetosReference = getProjetosCollectionReference(empresaId).withConverter(projetoConverter);
  return query(projetosReference, where("deletedAt", "==", null));
}

/**
 * Get projetos for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - Projetos document snapshot
 */
export function getProjetosAll(empresaId: TEmpresa["id"]) {
  const q = queryProjetos(empresaId);
  return getDocs(q);
}

/**
 * Get started projetos query
 * @param empresaId - Empresa id
 * @returns - projetos document reference
 */
// prettier-ignore
function queryProjetosEmpresa(empresaId: TEmpresa["id"]) {
  const projetosReference = getProjetosCollectionReference(empresaId).withConverter(projetoConverter);

   return query(
     projetosReference,
     where("deletedAt", "==", null),
   );
}

/**
 * Get projetos for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - Projetos document snapshot
 */
export function getProjetosEmpresa(empresaId: TEmpresa["id"]) {
  const q = queryProjetosEmpresa(empresaId);
  return getDocs(q);
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param projetoId - Projeto id to update
 * @param projeto - Projeto data
 * @returns - Promise with the projeto reference
 */
export async function updateProjeto(empresaId: TEmpresa["id"], projetoId: TProjeto["id"], projeto: TProjetoForm) {
  const projetoDocumentReference = getProjetoDocumentReference(empresaId, projetoId).withConverter(projetoConverter);
  const currentUserId = getCurrentUser()?.uid;

  const { images } = projeto;
  const collectionReference = getProjetosCollectionReference(empresaId);
  const projetoDocument = doc(collectionReference);
  const anexosPromises = images
    .filter((file: FileWithPath) => file.lastModified)
    .map<Promise<TFileStored>>((file: FileWithPath) =>
      uploadAnexosFilesToStorage(empresaId, projetoDocument.id, file).then(async (uploadTaskSnapshot) => {
        const url = await getDownloadURL(uploadTaskSnapshot.ref);

        return {
          path: url,

          name: uploadTaskSnapshot.metadata.name,

          size: uploadTaskSnapshot.metadata.size,

          type: uploadTaskSnapshot.metadata.contentType || ""
        };
      })
    );

  const filterImage = images.filter((file: FileWithPath) => !file.lastModified);
  // eslint-disable-next-line compat/compat
  const anexos = await Promise.all(anexosPromises);
  return updateDoc(projetoDocumentReference, {
    ...projeto,
    images: [...anexos, ...filterImage],

    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Delete a projeto to the database
 * @param empresaId - Empresa id to delete the projeto
 * @param projeto - Projeto to delete
 * @returns - Promise
 */
export function deleteProjeto(empresaId: TEmpresa["id"], projeto: TProjeto) {
  const projetoDocumentReference = getProjetoDocumentReference(empresaId, projeto.id);
  return updateDoc(projetoDocumentReference, {
    deletedAt: serverTimestamp()
  });
}

/**
 * Get projeto de inovação item
 * @param empresaId - Empresa id to start a new projeto de inovação
 * @param projetoId - projeto de inovação form fields
 * @param licenciadaId - Licenciada Id
 * @returns - projeto de inovação document item
 */
export function getProjetosItem(
  empresaId: TEmpresa["id"],
  projetoId: TProjeto["id"],
  licenciadaId?: TLicenciada["id"] | undefined
) {
  const projetoDocumentReference = getProjetoDocumentReference(empresaId, projetoId, licenciadaId);
  return getDoc(projetoDocumentReference.withConverter(projetoConverter));
}

// eslint-disable-next-line import/no-unused-modules
type TLicenciadoFormFields = Omit<
  TLicenciado,
  "id" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TLicenciado, "id">>;

// eslint-disable-next-line import/no-unused-modules
type TLicenciadoDatabaseFields = Pick<TLicenciado, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TLicenciadoForm = TLicenciadoFormFields & TLicenciadoDatabaseFields;
/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param licenciadoId - Licenciado id to update
 * @param licenciado - Licenciado data
 * @returns - Promise with the licenciado reference
 */
export function updateLicenciado(
  empresaId: TEmpresa["id"],
  licenciadoId: TLicenciado["id"],
  licenciado: TLicenciadoForm
) {
  const licenciadoDocumentReference = getLicenciadosDocumentReference(empresaId, licenciadoId).withConverter(
    licenciadoConverter
  );
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(licenciadoDocumentReference, {
    ...licenciado,
    updatedAt: serverTimestamp(),
    updatedBy: currentUserId
  });
}

/**
 * Get licenciado document reference from empresaId
 * @param empresaId - Empresa id to get the licenciado document reference
 * @param licenciadoId - Licenciado id to get the licenciado document reference
 * @returns - Licenciado document reference
 */
function getLicenciadosDocumentReference(empresaId: TEmpresa["id"], licenciadoId: TLicenciado["id"]) {
  const licenciadoCollectionReference = getLicenciadosCollectionReference(empresaId);
  return doc(licenciadoCollectionReference, licenciadoId);
}

/**
 * Get licenciado collection reference from empresaId
 * @param empresaId - Empresa id to get the licenciados collection reference
 * @returns - Licenciados collection reference
 */
function getLicenciadosCollectionReference(empresaId: TEmpresa["id"]) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId);
  return collection(empresaDocumentReference, "licenciado");
}

const licenciadoConverter: FirestoreDataConverter<TLicenciado> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    // prettier-ignore
    const { ...document } = snap.data() as TLicenciado;

    const data: TLicenciado = {
      ...document,
      id: snap.id
    };

    return subject("TLicenciado", data);
  }
};

/**
 * Add a new Acesso to the given empresa
 * @param empresaId - Empresa id to add the Acesso
 * @param licenciado - Acesso data
 * @returns - Acesso document reference
 */
export function addLicenciado(empresaId: TEmpresa["id"], licenciado: TLicenciadoForm) {
  const licenciadoCollectionReference = getLicenciadosCollectionReference(empresaId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const currentUserId = getCurrentUser()?.uid;
  delete licenciado.id;

  return addDoc(licenciadoCollectionReference, {
    ...licenciado,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    createdBy: currentUserId,
    deletedAt: null
  });
}

/**
 * Get started projetos query
 * @param empresaId - Empresa id
 * @returns - projetos document reference
 */
function queryLicenciados(empresaId: TEmpresa["id"]) {
  const licenciadosReference = getLicenciadosCollectionReference(empresaId).withConverter(licenciadoConverter);
  return query(licenciadosReference, where("deletedAt", "==", null));
}

/**
 * Get projetos for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - Projetos document snapshot
 */
export function getLicenciados(empresaId: TEmpresa["id"]) {
  const q = queryLicenciados(empresaId);
  return getDocs(q);
}

// eslint-disable-next-line import/no-unused-modules
type TEmbaixadorFormFields = Omit<
  TEmbaixador,
  "id" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TEmbaixador, "id">>;

// eslint-disable-next-line import/no-unused-modules
type TEmbaixadorDatabaseFields = Pick<TEmbaixador, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
// eslint-disable-next-line import/no-unused-modules
export type TEmbaixadorForm = TEmbaixadorFormFields & TEmbaixadorDatabaseFields;

// eslint-disable-next-line import/no-unused-modules
type TEmbaixadorPessoaFormFields = Omit<
  TEmbaixadorPessoa,
  "id" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy" | "deletedAt"
> &
  Partial<Pick<TEmbaixador, "id">>;

// eslint-disable-next-line import/no-unused-modules
type TEmbaixadorPessoaDatabaseFields = Pick<TEmbaixadorPessoa, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
// eslint-disable-next-line import/no-unused-modules
export type TEmbaixadorPessoaForm = TEmbaixadorPessoaFormFields & TEmbaixadorPessoaDatabaseFields;

/**
 * Add a new Acesso to the given empresa
 * @param empresaId - Empresa id to add the Acesso
 * @param embaixador - Acesso data
 * @returns - Acesso document reference
 */
export function addEmbaixador(empresaId: TEmpresa["id"], embaixador: TEmbaixadorForm) {
  const collectionReference = getEmbaixadorCollectionReference(empresaId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const currentUserId = getCurrentUser()?.uid;

  return addDoc(collectionReference, {
    ...embaixador,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    createdBy: currentUserId,
    deletedAt: null
  });
}

/**
 * Add a new Acesso to the given empresa
 * @param empresaId - Empresa id to add the Acesso
 * @param embaixadorPessoa - dados do embaixador
 * @returns - Acesso document reference
 */
export function addEmbaixadorPessoa(empresaId: TEmpresa["id"], embaixadorPessoa: TEmbaixadorPessoaForm) {
  const collectionReference = getEmbaixadorPessoaCollectionReference(empresaId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const currentUserId = getCurrentUser()?.uid;

  return addDoc(collectionReference, {
    ...embaixadorPessoa,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    createdBy: currentUserId,
    deletedAt: null
  });
}

/**
 * Get licenciado collection reference from empresaId
 * @param empresaId - Empresa id to get the licenciados collection reference
 * @param licenciadaId - Licenciada id
 * @returns - Licenciados collection reference
 */
function getEmbaixadorCollectionReference(empresaId: TEmpresa["id"], licenciadaId?: TLicenciada["id"] | undefined) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId, licenciadaId);
  return collection(empresaDocumentReference, "embaixadores");
}

/**
 * Get licenciado collection reference from empresaId
 * @param empresaId - Empresa id to get the licenciados collection reference
 * @param licenciadaId - Licenciada id
 * @returns - Licenciados collection reference
 */
function getEmbaixadorPessoaCollectionReference(
  empresaId: TEmpresa["id"],
  licenciadaId?: TLicenciada["id"] | undefined
) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId, licenciadaId);
  return collection(empresaDocumentReference, "embaixadoresPessoas");
}

/**
 * Get started projetos query
 * @param empresaId - Empresa id
 * @returns - projetos document reference
 */
function queryEmbaixador(empresaId: TEmpresa["id"]) {
  const licenciadosReference = getEmbaixadorCollectionReference(empresaId).withConverter(embaixadorConverter);
  return query(licenciadosReference, where("deletedAt", "==", null));
}

/**
 * Get started projetos query
 * @param empresaId - Empresa id
 * @returns - projetos document reference
 */
function queryEmbaixadorPessoa(empresaId: TEmpresa["id"]) {
  const licenciadosReference = getEmbaixadorPessoaCollectionReference(empresaId).withConverter(embaixadorConverter);
  return query(licenciadosReference, where("deletedAt", "==", null));
}
/**
 * Get projetos for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - Projetos document snapshot
 */
export function getEmbaixador(empresaId: TEmpresa["id"]) {
  const q = queryEmbaixador(empresaId);
  return getDocs(q);
}

/**
 * Get projetos for a empresa
 * @param empresaId - Empresa id to get comitês
 * @returns - Projetos document snapshot
 */
export function getEmbaixadorPessoa(empresaId: TEmpresa["id"]) {
  const q = queryEmbaixadorPessoa(empresaId);
  return getDocs(q);
}

/**
 * Get all embaixadores by projeto ID
 * @param empresaId - Empresa ID
 * @param projeto - Projeto
 * @param licenciadaId - Licenciada ID
 * @returns - Promise with all embaixadores
 */
export async function getEmbaixadoresByProjeto(
  empresaId: TEmpresa["id"],
  projeto: TProjeto | string,
  licenciadaId?: TLicenciada["id"] | undefined
) {
  const projetoData =
    typeof projeto === "object"
      ? projeto
      : await getDoc(
          getProjetoDocumentReference(empresaId, projeto, licenciadaId).withConverter(projetoConverter)
        ).then((document_) => document_.data() as TProjeto);

  const embaixadoresIdsInProjeto = projetoData.embaixadores || [];
  const ebc = getEmbaixadorCollectionReference(empresaId, licenciadaId).withConverter(embaixadorConverter);

  const embaixadoresPromises = embaixadoresIdsInProjeto.map((embaixadorId) => {
    const embaixadorReference = doc(ebc, embaixadorId);
    return getDoc(embaixadorReference);
  });

  const embaixadores = await Promise.all(embaixadoresPromises);
  return embaixadores.map((document_) => document_.data()).filter((item) => !!item) as TEmbaixador[];
}

/**
 * Get all embaixadores by projeto ID
 * @param empresaId - Empresa ID
 * @param projeto - Projeto
 * @param licenciadaId - Licenciada ID
 * @returns - Promise with all embaixadores
 */
export async function getEmbaixadoresPessoasByProjeto(
  empresaId: TEmpresa["id"],
  projeto: TProjeto | string,
  licenciadaId?: TLicenciada["id"] | undefined
) {
  const projetoData =
    typeof projeto === "object"
      ? projeto
      : await getDoc(
          getProjetoDocumentReference(empresaId, projeto, licenciadaId).withConverter(projetoConverter)
        ).then((document_) => document_.data() as TProjeto);
  const embaixadoresIdsInProjeto = projetoData.embaixadoresPessoas || [];
  const ebc = getEmbaixadorPessoaCollectionReference(empresaId, licenciadaId).withConverter(embaixadorConverter);

  const embaixadoresPromises = embaixadoresIdsInProjeto.map((embaixadorId) => {
    const embaixadorReference = doc(ebc, embaixadorId);
    return getDoc(embaixadorReference);
  });

  const embaixadores = await Promise.all(embaixadoresPromises);
  return embaixadores.map((document_) => document_.data()).filter((item) => !!item) as TEmbaixadorPessoa[];
}

const embaixadorConverter: FirestoreDataConverter<TEmbaixador> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    // prettier-ignore
    const { ...document } = snap.data() as TEmbaixador;

    const data: TEmbaixador = {
      ...document,
      id: snap.id
    };

    return subject("TEmbaixador", data);
  }
};

// /**
//  * Get tipo de inovação item
//  * @param empresaId - Empresa id
//  * @param projetoId - Projeto id
//  * @param licenciadaId - Licenciada id
//  * @returns - tipo de inovação document item
//  */
// export function getProjetoItem(empresaId: TEmpresa["id"], projetoId: TProjeto["id"], licenciadaId?: TLicenciada["id"]) {
//   const documentReference = getProjetoDocumentReference(empresaId, projetoId, licenciadaId);
//   return getDoc(documentReference.withConverter(projetoConverter));
// }
