import { Card, Divider, Grid, Text } from "@mantine/core";
import EmpresasConvidadas from "./EmpresasConvidadas";
import EmpresasParticipantes from "./EmpresasParticipantes";
import QuestionariosRespondidos from "./QuestionariosRespondidos";
// import { useParams } from "react-router-dom";

const CardEmpresaItem = ({ projeto }: { projeto: TProjeto | null | undefined }) => {
  const space = "space-between";
  const idEmpresa =
    projeto && projeto?.refPath.includes("licenciadas/")
      ? projeto?.refPath.split("/")[3]
      : projeto?.refPath.split("/")[1];

  return (
    <Card withBorder shadow="md" style={{ flex: 1 }}>
      <Text size="xl">{projeto?.nome}</Text>
      <Divider my="md" />
      <Grid justify={space}>
        <Grid.Col span={3}>
          <EmpresasParticipantes idEmpresa={idEmpresa} size="xl" fw={700} idProjeto={projeto?.id || ""} />
        </Grid.Col>
        <Grid.Col span={3}>
          <EmpresasConvidadas idEmpresa={idEmpresa} size="xl" fw={700} idProjeto={projeto?.id || ""} />
        </Grid.Col>
        <Grid.Col span={3}>
          <QuestionariosRespondidos idEmpresa={idEmpresa} size="xl" fw={700} idProjeto={projeto?.id || ""} />
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default CardEmpresaItem;
