/* eslint-disable compat/compat */
interface TEmpresaData {
  nome_fantasia: string;
  razao_social: string;
  email: string | null;
  ddd_telefone_1: string;
  cnae_fiscal: number;
  cnae_fiscal_descricao: string;
  type?: string | undefined;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  municipio: string;
  uf: TEstado;
}

const CNPJ_LENGTH = 14;

export const getEmpresaDataByCnpj = async (cnpj: TEmpresa["cnpj"]) => {
  if (!cnpj || cnpj.length !== CNPJ_LENGTH) {
    return;
  }

  const response = await fetch(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`);

  if (!response.ok) {
    const retorno: TEmpresaData = {
      nome_fantasia: "ERRO",
      razao_social: "",
      email: null,
      ddd_telefone_1: "",
      cnae_fiscal: 0,
      cnae_fiscal_descricao: "",
      type: undefined,
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      municipio: "",
      uf: "PR"
    };
    return retorno;
  }

  return response.json() as Promise<TEmpresaData>;
};
