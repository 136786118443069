import { Flex, Group, Loader, Space, Table, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import useGetProjetoItem from "@/modules/analisar-resposta/components/questionario-item/useGetProjetoNew";
import { RankingListPaginator } from "@/modules/diagnostico/components/ranking-list/components/RankingListPaginator";
import { usePaginator } from "@/modules/diagnostico/components/ranking-list/hooks/usePaginator";
import { getEmbaixadoresByProjeto, getEmbaixadoresPessoasByProjeto } from "@/modules/projetos/firestore";
import useUserStore from "@/modules/users/store";
import ExportExcel from "./ExportExcel";
import GetEmbaixadores from "./get-embaixador";
import TableEmbaixador from "../table-item-embaixador";

export interface TDadosRelatorio {
  id: string;
  nome: string;
  empresas: TEmpresa[];
}

const message = "Ocorreu um erro ao carregar os dados:";

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TableEmbaixadores = ({ tipo }: { tipo: string }) => {
  const [dadosRelatorio, setDadosRelatorio] = useState<(TDadosRelatorio)[]>([]);
  const projeto = useUserStore((state) => state.activeProjeto) as string;
  const activeEmpresaId = useUserStore((state) => state.activeEmpresaId);
  const { data } = useGetProjetoItem({ idProjeto: projeto || "" });
  const [loading, setLoading] = useState(false);
  const { currentPageContent, currentPage, pageSize, setCurrentPage, setPageSize } = usePaginator(dadosRelatorio);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
      } catch (error) {
        console.error(message, error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [data?.minimoResposta])

  const loadData = async () => {
    setLoading(true);
    try {
      const empresasProjeto = await GetEmbaixadores(projeto || "");
      const embaixadoresProjeto =
        tipo === "pessoas" ?
          await getEmbaixadoresPessoasByProjeto(activeEmpresaId || "", projeto) :
          await getEmbaixadoresByProjeto(activeEmpresaId || "", projeto);
      const dadosRelatorio = embaixadoresProjeto.map((embaixador) => {
        const empresasDoEmbaixador = empresasProjeto.filter((empresa) =>
          empresa.embaixadores?.some(
            (document_) => document_.cadastroReference === embaixador.id
          )
        );
        if (empresasDoEmbaixador.length === 0) {
          return null;
        }
        return {
          id: embaixador.id,
          nome: embaixador.nome,
          empresas: empresasDoEmbaixador
        };
      }).filter((item) => item !== null) as unknown as TDadosRelatorio[];
      setDadosRelatorio(dadosRelatorio);
    } catch (error) {
      console.error("Erro ao carregar dados do relatório:", error);
    } finally {
      setLoading(false);
    }
  }


  const rows = currentPageContent.map((item) => (
    <TableEmbaixador key={item.id} data={item} />
  ));

  return (
    <>
      <Group justify={"space-between"} mb={10}>
        <Text size={"md"} fw={700}>Empresas inscritas por {tipo === "pessoas" ? "Embaixador" : "Entidade"}</Text>
        <Group>
          {/* <ModalFilter setFilter={setFilter} /> */}
          <ExportExcel embaixadores={dadosRelatorio} />
        </Group>
      </Group>
      <div style={{ overflowX: "auto" }}>

        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{tipo === "pessoas" ? "Embaixador" : "Entidade"}</Table.Th>
              <Table.Th>Nº Empresas</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{loading ? <> </> : rows}</Table.Tbody>
        </Table>
        <Flex p="sm" justify="flex-end" gap="sm">
          <RankingListPaginator
            numberOfItems={dadosRelatorio.length}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
          />
        </Flex>
      </div>
      <Space h={"md"} />
      {loading ? <Group justify={"center"} align={"center"}>
        <Loader color="blue" />
      </Group> : <></>}
    </>
  )
};

export default TableEmbaixadores;
