/* eslint-disable compat/compat */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Card, Checkbox, Flex, Group, Input, Loader, Space, Text } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetConvites from "@/modules/analisar-resposta/components/ranking-list/useGetConvites";
import useGetEmpresaById from "@/modules/analisar-resposta/components/ranking-list/useGetEmpresas";
import { getEmpresaById } from "@/modules/empresas/firestore";
import { getQuestionarioAplicadoArray } from "@/modules/grupos-diagnostico/firestore";
import { getMediaGruposCollectionReference } from "@/modules/media-grupo/firestore";
import { getMediaGrupoNota } from "@/modules/media-grupo/util";
import useUserStore from "@/modules/users/store";
import { DEZ } from "@/utils/constants";
import { StatusItem, Fase } from ".";
import { RankingListBase } from "./components/RankingListBase";
import { RankingListPaginator } from "./components/RankingListPaginator";
import StatusComponente from "./components/StatusComponente";
import { usePaginator } from "./hooks/usePaginator";
import { useSearch } from "./hooks/useSearch";
import RankingItem from "../ranking-item";

export type NotaEmpresa = {
  empresaId: string;
  nomeEmpresa: string;
  media: number;
  mediaEmpresa: number;
  peso: number;
  habilitada: boolean;
};

const SEARCH_OPTIONS = { includeScore: true, keys: ["nomeEmpresa"] };

const formatEmpresa = (empresa: TEmpresa | null, projeto: TProjeto) => {
  return {
    convite: false,
    createdAt: new Date(),
    createdBy: "",
    id: empresa?.id,
    idColaboradorConvidado: "",
    idEmpresa: empresa?.id,
    idEmpresaColaboradorConvidado: empresa?.id,
    idProjeto: projeto.id,
    idQuestionario: ""
  } as TConvite;
};

const RankingList = ({ projeto }: { projeto: TProjeto }) => {
  const { id: projetoId } = useParams<{ id: string }>();
  const { activeEmpresaId } = useUserStore();
  const [mostrarHabilitadas, setMostrarHabilitadas] = useState(false);
  const idEmpresaProjeto =
    projeto && projeto?.refPath.includes("licenciadas/")
      ? projeto?.refPath.split("/")[3]
      : projeto?.refPath.split("/")[1];

  const { data: convites } = useGetConvites();
  const { data: empresa } = useGetEmpresaById(idEmpresaProjeto);

  const newEmpresa = formatEmpresa(empresa ?? null, projeto);

  const [selects, setSelects] = useState<TEmpresa[]>([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notasEmpresas, setNotasEmpresas] = useState<NotaEmpresa[]>([]);

  const { handleSearchEvent, value } = useSearch(notasEmpresas, SEARCH_OPTIONS);
  const notasFiltradas = mostrarHabilitadas ? value.filter((empresa) => empresa.habilitada) : value;
  const { currentPageContent, currentPage, pageSize, setCurrentPage, setPageSize } = usePaginator(notasFiltradas);

  const allConvites = [...(convites ?? []), newEmpresa];

  const getAllNotasInProjeto = async (empresaId: TEmpresa["id"], projetoId: TProjeto["id"]) => {
    const questionariosOfProjeto = await getQuestionarioAplicadoArray(empresaId, projetoId);
    const allNotas = await Promise.all(
      questionariosOfProjeto.docs.map((q) => {
        const questionario = q.data();
        const notasCollection = getMediaGruposCollectionReference(empresaId, questionario.id);
        return getDocs(notasCollection);
      })
    );

    return allNotas.flatMap((n) => n.docs);
  };

  const getNotas = async () => {
    if (!projetoId || !activeEmpresaId) {
      return [];
    }

    const notasDocuments = await getAllNotasInProjeto(activeEmpresaId, projetoId);
    const notasFound = notasDocuments.map((document_) => document_.data());
    const notasGrouped = new Map<string, TMediaGrupo[]>();
    const empresa = await getEmpresaById(notasFound[0]?.empresaId || "");

    for (const nota of notasFound) {
      const notasInGroup = notasGrouped.get(nota.empresaId) || [];
      notasInGroup.push(nota);
      notasGrouped.set(nota.empresaId, notasInGroup);
    }

    return [...notasGrouped.entries()].map(([empresaId, notas]) => {
      const mediaEmpresa = notas.reduce(
        (accumulator, nota) => accumulator + nota.perguntasSum / nota.respostasCount,
        0
      );
      return {
        empresaId,
        nomeEmpresa: empresa?.data()?.nomeFantasia || "",
        media: getMediaGrupoNota({ respostasCount: notas.length, perguntasSum: mediaEmpresa }),
        mediaEmpresa: notas.reduce((accumulator, nota) => accumulator + nota.media, 0) / DEZ / notas.length,
        peso: notas.reduce((accumulator, nota) => accumulator + nota.pesoSum, 0),
        habilitada:
          notas.length > 0 && notas[0] !== undefined && notas[0].respostasCount >= (projeto?.minimoResposta || 0)
      };
    });
  };

  const loadData = async () => {
    setLoading(true);
    const notas = await getNotas();
    notas.sort((a, b) => b.mediaEmpresa - a.mediaEmpresa);
    setNotasEmpresas(notas);
    setLoading(false);
  };

  const changeSelects = (data: TEmpresa, statusItem: TStatus) => {
    setSelects((selects: (TEmpresa & StatusItem)[]) => {
      const arraySelects = [...selects];
      return arraySelects.some((item: TEmpresa) => item?.id === data?.id)
        ? arraySelects.filter((item: TEmpresa) => item?.id !== data?.id)
        : [...arraySelects, { ...data, statusItem }];
    });
  };

  useEffect(() => {
    setSelects([]);
  }, [reload]);

  useEffect(() => {
    loadData();
  }, [idEmpresaProjeto, notasEmpresas?.length, allConvites.length]);

  if (loading) {
    return (
      <RankingListBase projeto={projeto} empresasSelected={selects} setReload={setReload}>
        <Group align={"center"} justify={"center"}>
          <Loader color="blue" />
        </Group>
      </RankingListBase>
    );
  }

  if (!loading && notasEmpresas.length === 0) {
    return (
      <RankingListBase projeto={projeto} empresasSelected={selects} setReload={setReload}>
        <Group align={"center"} justify={"center"}>
          <Space h="md" />
          <Text fw={"bold"}>Nenhuma empresa convidada!</Text>
          <Space h="md" />
        </Group>
      </RankingListBase>
    );
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    const searchTimeout = 500;

    handleSearchEvent(searchValue, searchTimeout);
  };

  return (
    <Card withBorder shadow="md" style={{ overflow: "auto" }}>
      <Space h="md" />
      <Group justify={"space-between"} align="center">
        <Text size="xl">Ranking de empresas</Text>
        <StatusComponente selects={selects} setReload={setReload} fases={projeto.fases as Fase[]} />
        <Flex>
          <Flex align="center">
            <Checkbox
              checked={mostrarHabilitadas}
              onChange={(event) => setMostrarHabilitadas(event.currentTarget.checked)}
            />
            <Text fz="md" fw={400} ml="sm">
              Mostrar apenas empresas habilitadas
            </Text>
            <Input
              onChange={handleSearchChange}
              placeholder="Pesquisar empresas"
              size="sm"
              radius="lg"
              ml={20} // ← distância mínima de 20px do checkbox
              leftSection={<IconSearch size="1rem" />}
            />
          </Flex>
        </Flex>
      </Group>
      <Space h="md" />

      {currentPageContent.map((item, index) => (
        <RankingItem
          key={item.empresaId}
          empresa={item}
          selects={selects}
          changeSelects={changeSelects}
          projeto={projeto}
          posicao={index}
          reload={reload}
        />
      ))}

      <Flex p="sm" justify="end" gap="sm">
        <RankingListPaginator
          numberOfItems={notasEmpresas.length}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
          onPageSizeChange={setPageSize}
        />
      </Flex>
    </Card>
  );
};

export default RankingList;
