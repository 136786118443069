import { Anchor, Badge, Button, Card, Flex, Grid, Group, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import ProjetosLabel from "@/modules/questionario-de-inovacao/components/projetos-label";
import { getCurrentUser } from "@/services/auth";
import useGetResposta from "../questionario-list/useGetResposta";

interface Colaborador {
  colaboradorId: string;
  nome: string;
  idProjeto: string;
  refPath: string;
  id: string;
}

const MAGIC_NUMBER = 1000;

// prettier-ignore
const QuestionarioItem = ({ questionario }: { questionario: TQuestionario & Colaborador }) => {
  const user = getCurrentUser();
  // const idEmpresa = questionario?.refPath.split("/")[1];
  const idEmpresa =
    questionario && questionario?.refPath.includes("licenciadas/")
      ? questionario?.refPath.split("/")[3]
      : questionario?.refPath.split("/")[1];
  const teste = useGetResposta({ id: questionario?.colaboradorId || "" });
  const { data } = teste
  const pausado = data && data?.find((item) => item.idRespondente === questionario?.colaboradorId && item.idQuestionario === questionario.id) && data?.find((item) => item.idRespondente === user?.uid && item.idQuestionario === questionario.id)?.pausado
  const isSmMarches = useMediaQuery("(max-width: 48em)");

  return (
    <>
      <Space h="md" />
      <Card withBorder shadow="md" style={{ flex: 1, width: isSmMarches ? MAGIC_NUMBER : "100%" }}>
        <Grid align="center">
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do projeto
              </Text>
              <ProjetosLabel id={questionario?.idProjeto} idEmpresa={idEmpresa} />
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex mih={50} gap="xs" justify="center" align="flex-start" direction="column" wrap="wrap">
              <Text fw={300} fz="xs">
                Nome do questionário
              </Text>
              <Text fw={500} fz="md">
                {questionario.nome}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group align={"center"} justify="center">
              {data && data?.filter((item) => item.idRespondente === questionario?.colaboradorId && item.idQuestionario === questionario.id)?.length > 0 && !pausado ?
                <Badge
                  radius="md"
                  size="xl"
                  variant="outline"
                  style={{ background: "rgba(244, 245, 246, 1)" }}
                  color="rgba(244, 245, 246, 1)"
                >
                  <Text tt="capitalize" fz="xs" fw={500} color={"black"}>
                    Respondido
                  </Text>
                </Badge>
                :
                <Badge
                  radius="md"
                  size="xl"
                  variant="outline"
                  style={{ background: "rgba(105, 198, 105, 0.1)" }}
                  color={pausado ? "rgba(58, 155, 217, 1)" : "rgba(105, 198, 105, 1)"}
                >
                  <Text tt="capitalize" fz="xs" fw={500}>
                    {pausado ? "Questionario pausado" : "Aguardando resposta"}
                  </Text>
                </Badge>}
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Group align={"center"} justify="center">
              {data && data?.filter((item) => item.idRespondente === questionario?.colaboradorId && item.idQuestionario === questionario.id)?.length > 0 && !pausado ?
                <Anchor component={NavLink} to={`/visualizarQuestionario/${idEmpresa}/${questionario?.colaboradorId}/${questionario.id}`}><Button
                  type="button"
                  variant="transparent"
                  size="compact-sm"
                >
                  Ver respostas
                </Button>
                </Anchor> : <></>}
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default QuestionarioItem;
