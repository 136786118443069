import { getEmbaixadoresDasEmpresas } from "@/modules/empresas/firestore";

/**
 * Get campanhas
 * @param idProjeto - id projeto
 * @returns - campanhas
 */
// prettier-ignore
async function GetEmbaixadores(
  idProjeto: TProjeto["id"] | undefined
): Promise<TEmpresa[]> {
  if (idProjeto) {
    return await getEmbaixadoresDasEmpresas(idProjeto);
  }
  return [];
}

export default GetEmbaixadores;
