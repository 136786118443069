import { Button, Table } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useCallback } from "react";
import { TDadosRelatorio } from "../table-embaixadores/TableEmbaixadores";

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
// prettier-ignore
const TableItem = ({ data }: { data: (TDadosRelatorio) }) => {

  const handleDetailEmpresas = useCallback(() => {
    modals.openContextModal({
      modal: "detail-embaixador-empresas-modal",
      innerProps: { dados: data },
      size: "lg",
      title: "Lista de Empresas"
    });
  }, [data]);

  return (
    <Table.Tr key={data?.id}>
      <Table.Td>{data?.nome}</Table.Td>
      <Table.Td><Button onClick={handleDetailEmpresas} variant="transparent">{data?.empresas?.length.toString()}</Button></Table.Td>
    </Table.Tr>
  )
};

export default TableItem;
