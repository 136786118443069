/* eslint-disable react/jsx-no-undef */
import { Card, Flex, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { TDadosRelatorio } from "@/modules/relatorio/components/table-embaixadores/TableEmbaixadores";

/**
 * Ideias likes card
 * @param root0 readOnly
 * @param root0.empresa - dados da empresa
 * @returns JSX.Element
 */
function EmbaixadorEmpresasCard({ empresa }: { empresa: TEmpresa }) {
  return (
    <Card withBorder>
      <Flex direction="column" gap="xs">
        <Text size="lg" fw={600}>
          {empresa.nomeFantasia}
        </Text>
        <Text size="sm" c="dimmed">
          {empresa.cnpj} - {empresa.categorias} - {empresa.porte} - {empresa.endereco.estado}
        </Text>
      </Flex>
    </Card>
  );
}

/**
 * Detail ideia likes modal
 * @param root0 readOnly
 * @param root0.innerProps - dados do embaixador
 * @returns JSX.Element
 */
export function DetailEmbaixadorEmpresaModal({ innerProps }: ContextModalProps<{ dados: TDadosRelatorio }>) {
  const { dados } = innerProps;
  if (!dados.empresas || dados.empresas.length === 0) return null;
  return (
    <Flex direction="column" gap="sm">
      {dados.empresas?.map((empresa) => <EmbaixadorEmpresasCard key={empresa.id} empresa={empresa} />)}
    </Flex>
  );
}
