import { subject } from "@casl/ability";
import { PaginationState, SortingState } from "@tanstack/react-table";
import {
  FirestoreDataConverter,
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  or,
  query,
  serverTimestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { firestore } from "@/base";
import { getCurrentUser } from "@/services/auth";
import { generateQuery } from "@/utils/firestore";
import { getActiveLicenciadaDocumentReference, getLicenciadaDocumentReference } from "../licenciadas/firestore";

const DEFAULT_LIMIT = 10;
export const DEFAULT_SORTING: SortingState = [{ id: "nomeFantasia", desc: false }];
export const DEFAULT_PAGINATION: PaginationState = { pageIndex: 0, pageSize: DEFAULT_LIMIT };

export type TEmpresaDatabaseFields = Pick<TEmpresa, "createdBy" | "updatedBy"> & {
  createdAt: ReturnType<typeof serverTimestamp>;
  updatedAt?: ReturnType<typeof serverTimestamp>;
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
export type TEmpresaFormFields = Omit<
  TEmpresa,
  "id" | "refPath" | "createdAt" | "updatedAt" | "createdBy" | "updatedBy"
> &
  Partial<Pick<TEmpresa, "id" | "refPath">>;
export type TEmpresaForm = TEmpresaFormFields & TEmpresaDatabaseFields;

type TEmpresaDocument = Omit<TEmpresa, "createdAt" | "updatedAt" | "deletedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  deletedAt: Timestamp | null;
};

const empresaConverter: FirestoreDataConverter<TEmpresa> = {
  toFirestore(data) {
    delete data.id;
    delete data.refPath;
    // delete data.__caslSubjectType__;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TEmpresaDocument;

    const data: TEmpresa = subject("TEmpresa", {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate(),
      refPath: snap.ref.path
    });

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all empresas collection reference
 * @param licenciadaId - Licenciada id
 * @returns - Empresas collection reference
 */
function getEmpresasCollectionReference(licenciadaId?: TLicenciada["id"] | undefined) {
  const licenciadaDocumentReference = licenciadaId
    ? getLicenciadaDocumentReference(licenciadaId)
    : getActiveLicenciadaDocumentReference();

  if (licenciadaDocumentReference) {
    return collection(licenciadaDocumentReference, `empresas`);
  }
  return collection(firestore, `empresas`);
}
// prettier-ignore
/**
 * Get all empresas collection reference where the current user is the owner
 * @param uid - User id
 * @returns - Empresas collection reference
 */
function queryMyEmpresasCollectionReference(uid: string) {

  const empresasCollectionReference = getEmpresasCollectionReference();
  return query(
    empresasCollectionReference,
    or(where("createdBy", "==", uid), where("colaboradores", "array-contains", uid))
  );
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param uid - User id
 * @param role - role
 * @returns - Empresas collection reference
 */
// prettier-ignore
function queryMyEmpresasCollectionNewReference(uid: string, role: string) {
   const empresasCollectionReference = getEmpresasCollectionReference();

   if (role === "ADMIN") {
    return query(empresasCollectionReference);
   }

  return query(
    empresasCollectionReference,
    or(where("createdBy", "==", uid), where("colaboradores", "array-contains", uid))
  );
}

/**
 * Get a empresa document reference
 * @param empresaId - Empresa id
 * @param licenciadaId - Licenciada id
 * @returns - Empresa document reference
 */
export function getEmpresaDocumentReference(empresaId: TEmpresa["id"], licenciadaId?: TLicenciada["id"] | undefined) {
  const empresasCollectionReference = getEmpresasCollectionReference(licenciadaId).withConverter(empresaConverter);
  return doc(empresasCollectionReference, empresaId);
}

/**
 * Get a empresa document reference from path
 * @param empresaPath - Empresa path
 * @returns - Empresa document reference
 */
export function getEmpresaDocumentReferenceFromPath(empresaPath: string) {
  return doc(firestore, empresaPath).withConverter(empresaConverter);
}

/**
 * Get a empresa by cnpj
 * @param cnpj - Empresa cnpj
 * @param licenciadaId - Licenciada Id
 * @returns - Promise with the empresa snapshot
 * @throws - Error if the empresa is already registered
 */
async function getEmpresaByCnpj(cnpj: string, licenciadaId?: TLicenciada["id"] | undefined) {
  const empresasCollectionReference = getEmpresasCollectionReference(licenciadaId);
  const empresaQuery = query(empresasCollectionReference, where("cnpj", "==", cnpj));
  const { docs } = await getDocs(empresaQuery);
  return docs[0] && empresaConverter.fromFirestore(docs[0]);
}

/**
 * Add a empresa to the database
 * @param empresa - Empresa to add
 * @returns - Promise with the empresa reference
 */
export function addEmpresa(empresa: Omit<TEmpresaForm, "id" | "refPath">) {
  const empresasCollectionReference = getEmpresasCollectionReference();
  return addDoc(empresasCollectionReference, empresa);
}

/**
 * Add a empresa to the database
 * @param empresa - Empresa to add
 * @param licenciadaId - licenciada Id
 * @returns - Promise with the empresa reference
 */
export async function addEmpresaWithConverter(
  empresa: Omit<TEmpresaForm, "id" | "refPath">,
  licenciadaId?: TLicenciada["id"] | undefined
) {
  const empresasCollectionReference = getEmpresasCollectionReference(licenciadaId);
  const empresaWithSameCNPJ = await getEmpresaByCnpj(empresa.cnpj, licenciadaId);
  if (empresaWithSameCNPJ) {
    throw new Error("Empresa já cadastrada");
  }

  const empresaCreated = await addDoc(empresasCollectionReference, empresa);
  return await getDoc(empresaCreated.withConverter(empresaConverter));
}

/**
 * Update a empresa to the database
 * @param empresaId - Empresa id
 * @param empresa - Empresa to update
 * @returns - Promise with the empresa reference
 */
export function updateEmpresa(empresaId: TEmpresa["id"], empresa: TEmpresaForm) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId).withConverter(empresaConverter);
  const currentUserId = getCurrentUser()?.uid;
  return updateDoc(empresaDocumentReference, { ...empresa, updatedAt: serverTimestamp(), updatedBy: currentUserId });
}

/**
 * Delete a empresa to the database
 * @param empresa - Empresa to delete
 * @returns - Promise with the empresa reference
 */
export function deleteEmpresa(empresa: TEmpresa) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresa.id).withConverter(empresaConverter);
  return deleteDoc(empresaDocumentReference);
}

/**
 * Get a empresa by id
 * @param empresaId - Empresa id
 * @param licenciadaId - Licenciada Id
 * @returns - Promise with the empresa snapshot
 */
export function getEmpresaById(empresaId: TEmpresa["id"], licenciadaId?: TLicenciada["id"] | undefined) {
  const empresaDocumentReference = getEmpresaDocumentReference(empresaId, licenciadaId).withConverter(empresaConverter);
  return getDoc(empresaDocumentReference);
}

/**
 * Get all empresas from the database
 * @param uid - User id
 * @returns - Promise with all empresas
 */
export function getEmpresas(uid: string) {
  const queryEmpresasCollectionReference = queryMyEmpresasCollectionReference(uid).withConverter(empresaConverter);
  return getDocs(queryEmpresasCollectionReference);
}

// prettier-ignore
/**
 * Get all empresas from the database
 * @param uid - User id
 * @param role - role
 * @param root0 - Query options
 * @param root0.sorting - Sorting options
 * @param root0.filters - Filters options
 * @param root0.pagination - Pagination options
 * @param root0.paginationCursors - Array of pagination cursors (startAt, endAt)[]
 * @returns - Promise with all empresas
 */
export function getPaginatedEmpresas(
  uid: string,
  role = "",
  {
    sorting = [],
    filters = [],
    pagination = { pageIndex: 0, pageSize: DEFAULT_LIMIT },
    paginationCursors,
  }: TPaginatedQueryOptions<TEmpresa> = {}
): Promise<TPaginatedQueryResponse<TEmpresa>> {
  const queryEmpresasCollectionReference = queryMyEmpresasCollectionNewReference(uid, role).withConverter(empresaConverter);
  const qAll = generateQuery(queryEmpresasCollectionReference, { sorting, filters });
  const qPaginated = generateQuery(queryEmpresasCollectionReference, {
    sorting,
    filters,
    pagination,
    paginationCursors
  });

  // eslint-disable-next-line compat/compat
  return Promise.all([getDocs(qPaginated), getCountFromServer(qAll)]);
}

type TConviteDocument = Omit<TConvite, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

type TConviteDatabaseFields = Pick<TConvite, "createdBy" | "updatedBy"> & {
  deletedAt: ReturnType<typeof serverTimestamp> | null;
};
type TConviteFormFields = Omit<TConvite, "id"> & Partial<Pick<TEmpresa, "id">>;
export type TConviteForm = TConviteFormFields & TConviteDatabaseFields;

const conviteConverter: FirestoreDataConverter<TConvite> = {
  toFirestore(data) {
    delete data.id;
    return data;
  },
  fromFirestore(snap) {
    const { createdAt, updatedAt, ...document } = snap.data() as TConviteDocument;

    const data: TConvite = {
      ...document,
      id: snap.id,
      createdAt: createdAt.toDate()
    };

    if (updatedAt) {
      data.updatedAt = updatedAt.toDate();
    }
    return data;
  }
};

/**
 * Get all empresas collection reference
 * @returns - Empresas collection reference
 */
function getConviteCollectionReference() {
  return collection(firestore, "convites");
}
// /**
//  * Get a empresa document reference
//  * @param conviteId - Empresa id
//  * @returns - Empresa document reference
//  */
// function getConviteDocumentReference(conviteId: TConvite["id"]) {
//   const convitesCollectionReference = getConviteCollectionReference().withConverter(empresaConverter);
//   return doc(convitesCollectionReference, conviteId);
// }

/**
 * Get all empresas collection reference where the current user is the owner
 * @param uid - User id
 * @returns - Empresas collection reference
 */
function queryMyConvitesCollectionReference(uid: string) {
  const empresasCollectionReference = getConviteCollectionReference();
  return query(empresasCollectionReference, where("idColaboradorConvidado", "==", uid));
}

/**
 * Get all empresas from the database
 * @param uid - User id
 * @returns - Promise with all empresas
 */
export function getConvites(uid: string) {
  const queryEmpresasCollectionReference = queryMyConvitesCollectionReference(uid).withConverter(conviteConverter);
  return getDocs(queryEmpresasCollectionReference);
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param uid - User id
 * @returns - Empresas collection reference
 */
function queryMyConviteEmpresaCollectionReference(uid: string) {
  const empresasCollectionReference = getConviteCollectionReference();
  return query(empresasCollectionReference, where("idEmpresaColaboradorConvidado", "==", uid));
}

/**
 * Get all empresas from the database
 * @param uid - User id
 * @returns - Promise with all empresas
 */
// prettier-ignore
export function getConvitesEmpresa(uid: string) {
  const queryEmpresasCollectionReference = queryMyConviteEmpresaCollectionReference(uid).withConverter(conviteConverter);
  return getDocs(queryEmpresasCollectionReference);
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param idEmpresa - Empresa id
 * @param idProjeto - pojeto id
 * @returns - Empresas collection reference
 */
// prettier-ignore
function queryMyConvitesEmpresaCollectionReference(idEmpresa: TEmpresa["id"], idProjeto: TProjeto["id"] | undefined) {
  const empresasCollectionReference = getConviteCollectionReference();
   return query(
     empresasCollectionReference,
     where("idEmpresa", "==", idEmpresa),
     where("idProjeto", "==", idProjeto),
     where("convite", "==", false),
     where("idQuestionario", "==", "")
   );
}

/**
 * Get all empresas from the database
 * @param idEmpresa - Empresa id
 * @param idProjeto - pojeto id
 * @returns - Promise with all empresas
 */
// prettier-ignore
export function getConvitesEmpresaProjeto(idEmpresa: TEmpresa["id"], idProjeto: TProjeto["id"] | undefined) {
  const queryEmpresasCollectionReference = queryMyConvitesEmpresaCollectionReference(idEmpresa, idProjeto).withConverter(conviteConverter);
  return getDocs(queryEmpresasCollectionReference);
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param idEmpresa - Empresa id
 * @param idProjeto - projeto id
 * @returns - Empresas collection reference
 */
// prettier-ignore
function queryMyConvitesEmpresaParticipandoCollectionReference(idEmpresa: TEmpresa["id"], idProjeto: TProjeto["id"] | undefined) {
  const empresasCollectionReference = getConviteCollectionReference();
   return query(
     empresasCollectionReference,
     where("idEmpresa", "==", idEmpresa),
     where("idProjeto", "==", idProjeto),
     where("idQuestionario", "==", "")
   );
}

/**
 * Get all empresas from the database
 * @param idEmpresa - Empresa id
 * @param idProjeto - pojeto id
 * @returns - Promise with all empresas
 */
// prettier-ignore
export function getConvitesEmpresaParticipando(idEmpresa: TEmpresa["id"], idProjeto: TProjeto["id"] | undefined) {
  const queryEmpresasCollectionReference = queryMyConvitesEmpresaParticipandoCollectionReference(idEmpresa, idProjeto).withConverter(conviteConverter);
  return getDocs(queryEmpresasCollectionReference);
}

/**
 * Get a empresa document reference
 * @param conviteId - Empresa id
 * @returns - Empresa document reference
 */
function getConviteDocumentReference(conviteId: TEmpresa["id"]) {
  const empresasCollectionReference = getConviteCollectionReference().withConverter(conviteConverter);
  return doc(empresasCollectionReference, conviteId);
}

/**
 * Update a empresa to the database
 * @param conviteId - Empresa id
 * @param convite - Empresa to update
 * @returns - Promise with the empresa reference
 */
export function updateConvite(conviteId: TConvite["id"], convite: TConviteForm) {
  const conviteDocumentReference = getConviteDocumentReference(conviteId).withConverter(conviteConverter);
  return updateDoc(conviteDocumentReference, { ...convite });
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param idProjeto - pojeto id
 * @returns - Empresas collection reference
 */
// prettier-ignore
function queryConvitesProjetoCollectionReference(idProjeto: TProjeto["id"] | undefined) {
  const empresasCollectionReference = getConviteCollectionReference();
   return query(
     empresasCollectionReference,
     where("idProjeto", "==", idProjeto),
     where("convite", "==", false),
     where("idQuestionario", "==", "")
   );
}

/**
 * Get all empresas from the database
 * @param idProjeto - pojeto id
 * @returns - Promise with all empresas
 */
// prettier-ignore
export function getConvitesProjetos(idProjeto: TProjeto["id"] | undefined) {
  const queryEmpresasCollectionReference = queryConvitesProjetoCollectionReference(idProjeto).withConverter(conviteConverter);
  return getDocs(queryEmpresasCollectionReference);
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param idEmpresa - pojeto id
 * @param idColaborador - id do colaborador
 * @returns - Empresas collection reference
 */
// prettier-ignore
function queryConvitesEmpresaCollectionReference(idEmpresa: TEmpresa["id"] | undefined, idColaborador: TColaborador["id"]) {
  const empresasCollectionReference = getConviteCollectionReference();

   return query(
     empresasCollectionReference,
     where("idEmpresaColaboradorConvidado", "==", idEmpresa),
     where("idColaboradorConvidado", "==", idColaborador),
   );
}

/**
 * Get all empresas from the database
 * @param idEmpresa - pojeto id
 * @param idColaborador - id do colaborador
 * @returns - Promise with all empresas
 */
// prettier-ignore
export function getConvitesEmpresas(idEmpresa: TEmpresa["id"] | undefined, idColaborador: TColaborador["id"]) {
  const queryEmpresasCollectionReference = queryConvitesEmpresaCollectionReference(idEmpresa, idColaborador).withConverter(conviteConverter);
  return getDocs(queryEmpresasCollectionReference);
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param idEmpresa - pojeto id
 * @returns - Empresas collection reference
 */
// prettier-ignore
function queryConvitesEmpresaConvidadaCollectionReference(idEmpresa: TEmpresa["id"] | undefined) {
  const empresasCollectionReference = getConviteCollectionReference();

   return query(
     empresasCollectionReference,
     where("idEmpresaColaboradorConvidado", "==", idEmpresa),
   );
}

/**
 * Get all empresas from the database
 * @param idEmpresa - pojeto id
 * @returns - Promise with all empresas
 */
// prettier-ignore
export function getConvitesEmpresasConvidada(idEmpresa: TEmpresa["id"] | undefined) {
  const queryEmpresasCollectionReference = queryConvitesEmpresaConvidadaCollectionReference(idEmpresa).withConverter(conviteConverter);
  return getDocs(queryEmpresasCollectionReference);
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param categoria - categoria
 * @returns - Empresas collection reference
 */
export function getEmpresasByCategoria(categoria: string) {
  const empresasCollectionReference = getEmpresasCollectionReference().withConverter(empresaConverter);
  return query(empresasCollectionReference, where("categorias", "==", categoria));
}

/**
 * Get all empresas collection reference where the current user is the owner
 * @param idProjeto - id do projeto
 * @returns - Empresas collection reference
 */
export async function getEmbaixadoresDasEmpresas(idProjeto: string) {
  const snapshot = await getDocs(getEmpresasCollectionReference().withConverter(empresaConverter));

  return snapshot.docs
    .map((document_) => document_.data())
    .filter((empresa) => empresa.embaixadores?.some((empresa) => empresa.projetoReference === idProjeto));
}

// /**
//  * Get all empresas collection reference where the current user is the owner
//  * @param ids - ids empresas
//  * @returns - Empresas collection reference
//  */
//  function queryMyEmpresasRankingCollectionReference(ids: Array<string>) {
//    const empresasCollectionReference = getEmpresasCollectionReference();
//   return query(empresasCollectionReference, where("id", "in", ids));
// }
// /**
//  * Get all empresas from the database
//  * @param ids - ids empresas
//  * @returns - Promise with all empresas
//  */
// export function getEmpresasRanking(ids: Array<string>) {
//   const queryEmpresasCollectionReference =
//     queryMyEmpresasRankingCollectionReference(ids).withConverter(empresaConverter);
//   return getDocs(queryEmpresasCollectionReference);
// }
