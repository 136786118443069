import { Button, Group } from "@mantine/core";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useCallback } from "react";
import { DEZ, DOIS } from "@/utils/constants";
import { TDadosRelatorio } from "./TableEmbaixadores";

/* eslint-disable @typescript-eslint/no-floating-promises */
// prettier-ignore
const ExportExcel = ({ embaixadores }: { embaixadores: (TDadosRelatorio)[] }) => {

  const handleExport = useCallback(async () => {
    const formatEmpresa = embaixadores?.map((item) => {
      return [
        item.nome,
        item.empresas.length,
        item.empresas.map((empresa) => empresa.razaoSocial).join(", "),
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
    })
    await onExport(formatEmpresa);
  }, [embaixadores]);

  return <Group justify={"flex-end"}>
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <Button variant="filled" onClick={handleExport}>Exportar</Button>
  </Group>

};

export default ExportExcel;

/* eslint-disable sonarjs/cognitive-complexity */
// prettier-ignore
const onExport = async (csvData: (string | number | undefined)[][] | undefined) => {

  // Se csvData for undefined, interrompa a execução
  if (!csvData) return;

  const columns = ["Embaixador", "Nº Empresas", "Nome das Empresas"];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Embaixadores');

  const headerRow = worksheet.addRow(columns);

  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
  });

  if (Array.isArray(csvData)) {
    for (const rowData of csvData) {
      worksheet.addRow(Object.values(rowData)); // Removi a assertiva de tipo desnecessária
    }
  }
  calculateAndSetColumnWidths(worksheet, columns);
  try { // Adicionei um bloco try para capturar possíveis erros
    const buffer = await workbook.xlsx.writeBuffer(); // Aguardo a resolução da Promise
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `relatorio.xlsx`
    );
  } catch (error) { // Tratamento de erro
    console.error("Erro ao escrever buffer:", error);
  }
}

const calculateAndSetColumnWidths = (worksheet: ExcelJS.Worksheet, columns: string[]) => {
  const columnWidths = columns.map((col, index) => {
    let maxLength = col.length;
    worksheet.getColumn(index + 1).eachCell({ includeEmpty: true }, (cell) => {
      let cellValue = "";
      try {
        cellValue = cell.value !== null && cell.value !== undefined ? JSON.stringify(cell.value) : "";
      } catch (error) {
        console.error("Erro ao stringificar o valor da célula:", error);
      }
      maxLength = Math.max(maxLength, cellValue.length);
    });

    return maxLength < DEZ ? DEZ : maxLength + DOIS;
  });
  const validatedColumnWidths = columnWidths.map((width) => {
    if (typeof width !== "number" || width < 0) {
      throw new Error("Invalid column width");
    }
    return width;
  });
  for (const [index, column] of worksheet.columns.entries()) {
    column.width = validatedColumnWidths[index]; // já foi validado anteriormente, ignorar o warning do estlint
  }
};
